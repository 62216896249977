import React, {Component} from 'react';
import ContactForm from "./ContactForm/ContactForm";
import axios from 'axios';

import './Contact.css';


class Contact extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    handleEnvoiMail = (message) =>{
        axios.post("http://localhost/Client-project/server-ab-ramonage/front/sendMessage", message)
            .then(res => {
                console.log(res);
                this.props.history.push('/');
            })
            .catch(error=>{
                console.log(error)
            });
    }

    render() {
        return (
            <>
                <h2 className="section-title text-center">NOUS CONTACTER</h2>
                <div className="contact-section container mb-5">
                    <div className="contact-info">
                        <p className="contact-info-text text-center">Le moyen le plus rapide et efficace est de nous contacter au<br/>
                            <span className="phone-contact">06 50 17 67 72</span><br/>
                            Cependant vous pouvez nous laisser un message ici, nous y répondrons dans les plus brefs délais<br/>
                            Nous sommes ouvert du Lundi au Vendredi de 8h30 à 18h
                        </p>
                    </div>
                    <div className="contact-form-card">
                        <ContactForm sendMail={this.handleEnvoiMail}/>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;