import React, {Component} from 'react';
import {Row, Col, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

import './MobileFooter.css';

import logo from '../../../img/logos/logo-ab-ramonage.svg';


class MobileFooter extends Component {
    render() {
        return (
            <>
                <Row className="footer-mobile-section row p-0">
                    <Col className="mt-2" xs={3} md={2}>
                        <img className="footer-logo w-100" alt="AB-ramonage logo" src={logo}/>
                    </Col>
                    <Col className="footer-company p-0 mt-3" xs={5}>
                        <h2 className="footer-company-title">AB-RAMONAGE</h2>
                        <h3 className="footer-company-phone">06 50 17 67 72</h3>
                    </Col>
                    <Col className="p-0 pl-3 mt-2" xs={4} md={{span:3, offset: 1}}>
                        <Nav className="nav-footer-mobile">
                            <Nav.Link as={Link} className='nav-footer-mobile-link text-center mt-1 p-0' to="/" eventKey="/">- Accueil</Nav.Link>
                            <Nav.Link as={Link} className='nav-footer-mobile-link text-center mt-3 p-0' to="/prestations" eventKey="/prestations">- Nos préstations</Nav.Link>
                            <Nav.Link as={Link} className='nav-footer-mobile-link text-center mt-3 p-0' to="/contact" eventKey="/contact">- Nous contacter</Nav.Link>
                        </Nav>
                    </Col>
                    <Col xs={12}>
                        <p className="text-center mt-1 mb-0 company-sentence">Made by e-kigaï-dev © 2020 - Tous droits réservés</p>
                    </Col>
                </Row>
            </>
        );
    }
}

export default MobileFooter;