import React, {Component} from 'react';

import'./MobileHeader.css';

import header_background from '../../../img/backgrounds/mobile-header-bg';

import phone_icon from '../../../img/icons/telephone.svg';
import down_icon from '../../../img/icons/down-arrow.svg';


class MobileHeader extends Component {

    render() {
        return (
            <>
                <header className="mobile-header">
                    <div
                        style={{
                            backgroundImage: `url(${header_background})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        className="mobile-header-background">
                        <div className="row">
                            <div className="offset-6 col-6 mt-5">
                                <div className="btn btn-call center">
                                    <a className="link-call" title="lien pour appeler ab-ramonage" href="tel:+33650176772">APPELER<img className="icon-phone ml-2" src={phone_icon} alt="telephoner"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card card-main-title mx-auto">
                                <div className="card-body">
                                    <h1 className="main-title text-center">RAMONAGE TOUS CONDUITS  DEBISTRAGE, DEVIS GRATUIT, REMISE DE CERTIFICAT IMMÉDIATE DANS LE VAR</h1>
                                </div>
                            </div>
                        </div>

                        <div className="move-down center">
                            <img className="down-icon" alt="flèche vers le bas" src={down_icon}/>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default MobileHeader;