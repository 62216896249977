import React, {Component} from 'react';
import {Navbar, Nav, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';

import './MobileNavbar.css';

import icon_menu from '../../../img/icons/menu.svg';
import close_menu from '../../../img/icons/cancel.svg';
import logo from '../../../img/logos/logo-ab-ramonage.svg';


class MobileNavbar extends Component {
    render() {
        const closeMenu = () => document.querySelector(".closeMenu").click();
        const isMobile = window.innerWidth < 768;
        return (
            <>
                <Navbar className="p-0 top-bar-mobile" sticky="top">
                    <Col xs={8} className="">
                        <Navbar.Brand as={Link} to="/">
                            <img className="nav-mobile-logo w-25 ml-2" src={logo} alt="ab-ramonage logo"/>
                            <span className="brand-name ml-3">AB RAMONAGE<br/><span className="brand-phone">06 50 17 67 72</span></span>
                        </Navbar.Brand>
                    </Col>

                    <Menu
                        style={{height: '88vh'}}
                        overlayClassName={"closeMenu"}
                        className="nav-mobile"
                        customBurgerIcon={<img className="menu-icon" src={icon_menu} alt="icone menu"/> }
                        customCrossIcon={<img className="menu-icon-close" src={close_menu} alt="icone croix"/> }
                        width={isMobile? '200px' : '250px'}
                        right
                    >
                        <Nav.Link onClick={closeMenu} as={Link} className='nav-mobile-link btn btn-menu text-center mt-5' to="/" eventKey="/">Accueil</Nav.Link>
                        <Nav.Link onClick={closeMenu} as={Link} className='nav-mobile-link btn btn-menu text-center mt-3' to="/prestations" eventKey="/prestations">Nos prestations</Nav.Link>
                        <Nav.Link onClick={closeMenu} as={Link} className='nav-mobile-link btn btn-menu text-center mt-3' to="/contact" eventKey="/contact">Nous contacter</Nav.Link>
                        <a title="lien pour appeler ab-ramonage" onClick={closeMenu} href="tel:+33650176772" className='nav-mobile-link btn btn-menu text-center mt-3'>Nous appeler</a>
                        <h2 className="menu-title text-center mt-3">AB RAMONAGE</h2>
                        <div className="card card-menu mx-auto">
                            <div className="card-title text-center activities-title">
                                Ramonage tous conduits, foyer ouvert, insert, Poêle à granule à bois et à granulés
                                Chaudière fioul / gaz, debistrage
                            </div>
                            <div className="card-body mx-auto card-contact">
                                contact@abramonage.com<br/>
                                06 50 17 67 72<br/>
                                94 rue Eugène Meissel<br/>
                                83460 Taradeau<br/>
                                Siren 828 467 613
                            </div>
                        </div>
                        <p className="maker mt-3 text-center">Made by e-kigaï-dev © 2020 - Tous droits réservés</p>
                    </Menu>
                </Navbar>
            </>
        );
    }
}

export default MobileNavbar;