import React from 'react';
import {Col, Row} from "react-bootstrap";
import {withFormik} from "formik";
import * as Yup from "yup";

import './ContactForm.css';

const ContactForm = (props) => {
    return (
        <>
            <form className="row justify-content-around form-contact">
                <Col className="contact-form-card" xs={12} md={5}>
                    <Row className="justify-content-center mt-2">
                        <div className="form-group col-10 col-md-12">
                            <label className="form-label w-100">&nbsp;Nom :
                                <p className="errors-message">{props.errors.name && props.touched.name && props.errors.name}</p>
                                <input value={props.values.name} placeholder="Votre nom..." onBlur={props.handleBlur} onChange={props.handleChange} className="form-control contact-input" type="text" name="name"/>
                            </label>
                        </div>
                        <div className="form-group col-10 col-md-12">
                            <label className="form-label w-100">&nbsp;Téléphone :
                                <p className="errors-message">{props.errors.phone && props.touched.phone && props.errors.phone}</p>
                                <input value={props.values.phone} placeholder="Votre téléphone..." onBlur={props.handleBlur} onChange={props.handleChange} className="form-control contact-input" type="text" name="phone"/>
                            </label>
                        </div>
                        <div className="form-group col-10 col-md-12">
                            <label className="form-label w-100">&nbsp;Email :
                                <p className="errors-message">{props.errors.email && props.touched.email && props.errors.email}</p>
                                <input value={props.values.email} placeholder="Votre email..." onBlur={props.handleBlur} onChange={props.handleChange} className="form-control contact-input" type="email" name="email"/>
                            </label>
                        </div>
                    </Row>
                </Col>
                <Col className="contact-form-card" xs={12} md={5}>
                    <Row className="justify-content-center mt-2">
                        <div className="form-group col-10 col-md-12">
                            <label className="form-label">&nbsp;Message :
                                <p className="errors-message">{props.errors.message && props.touched.message && props.errors.message}</p>
                                <textarea value={props.values.message} placeholder="Votre message..." onBlur={props.handleBlur} onChange={props.handleChange} className="form-control textarea-contact" rows={10} cols={50} name="message"/>
                            </label>
                        </div>
                    </Row>
                </Col>
                <Col className="center mt-3 mb-3" xs={12}>
                    <div>
                        <button onClick={props.handleSubmit} className="btn btn-lg btn-submit" type="submit">ENVOYER</button>
                    </div>
                </Col>
            </form>
        </>
    );
};

export default withFormik({
    mapPropsToValues: () =>({
        name: '',
        phone: '',
        email: '',
        message: ''
    }),
    validationSchema : Yup.object().shape({
        name: Yup.string()
            .min(3, '! Votre nom doit contenir au moins 3 caractères')
            .max(50, '! Nom trop long')
            .required('! Obligatoire'),
        phone: Yup.string()
            .matches(/^0\d(\s|-)?(\d{2}(\s|-)?){4}$/,"! Numéro non valide")
            .required('! Obligatoire'),
        email: Yup.string()
            .email("! L'email n’a pas le bon format")
            .required('! Obligatoire'),
        message: Yup.string()
            .min(3, '! Le message doit contenir au moins 3 caractères')
            .max(1500, '! Le message est trop long')
            .required('! Obligatoire'),
    }),
    handleSubmit: (values, {props}) => {
        const nom = values.name + ' - Téléphone : ' + values.phone
        const message = {
            nom : nom,
            email: values.email,
            subject: 'AB-Ramonage - ' + values.email,
            contenu: values.message
        }
        props.sendMail(message);
    }
})(ContactForm);
