import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";

import './Prestations.css';

import devis_icon from '../../img/icons/24-hours.svg';
import dust_icon from '../../img/icons/duster.svg';
import fire_icon from '../../img/icons/fireplace (1).svg';
import medal_icon from '../../img/icons/medal.svg';

import banner_prest_bg from '../../img/backgrounds/banner-pres-mobile-bg';
import presta_banner from '../../img/backgrounds/prestation-bg-mobile';


class Prestations extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <h2 className="section-title text-center">NOS PRESTATIONS</h2>
                <Row className="mobile-prestations-card p-4 center">
                    <Row>
                        <Col>
                            <Col className="center" xs={12}>
                                <img className="prestations-icon" alt="devis rapide" src={devis_icon}/>
                            </Col>
                            <Col className="mt-3" xs={12}>
                                <h2 className="mobile-prestations-title text-center">DEVIS RAPIDE ET GRATUIT</h2>
                            </Col>
                        </Col>
                    </Row>
                    <Col className="prestations-text mt-3" xs={12} md={8} lg={6} xl={5}>
                        <p className="prestations-text-content text-center">
                            Chez AB Ramonage nous vous donnons une réponse rapide et vous établissons un
                            devis par téléphone. Parce que votre besoin n’attend pas nous vous apportons une
                            réponse par téléphone dans un délai maximum de 24h
                        </p>
                    </Col>
                </Row>
                <div
                    style={{
                        backgroundImage: `url(${banner_prest_bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="mobile-prestations-banner">

                </div>
                <Row className="mobile-prestations-card p-4 center">
                    <Row>
                        <Col>
                            <Col className="center" xs={12}>
                                <img className="prestations-icon" alt="devis rapide" src={dust_icon}/>
                            </Col>
                            <Col className="mt-3" xs={12}>
                                <h2 className="mobile-prestations-title text-center">RAMONAGE TOUS CONDUITS</h2>
                            </Col>
                        </Col>
                    </Row>
                    <Col className="prestations-text mt-3" xs={12} md={8} lg={6} xl={5}>
                        <p className="prestations-text-content text-center">
                            Parce que le ramonage est une obligation légale, nous répondons à tous types de demandes.
                            Que ce soit pour éliminer les suies d’une cheminée, d’un insert, d’un poêle à granule de bois
                            ou à granulés ainsi que pour une chaudière à fioul ou à gaz.
                        </p>
                    </Col>
                </Row>
                <hr className="w-50"/>
                <Row className="mobile-prestations-card p-4 center">
                    <Row>
                        <Col>
                            <Col className="center" xs={12}>
                                <img className="prestations-icon" alt="devis rapide" src={fire_icon}/>
                            </Col>
                            <Col className="mt-3" xs={12}>
                                <h2 className="mobile-prestations-title text-center">DÉBISTRAGE TOUS CONDUITS</h2>
                            </Col>
                        </Col>
                    </Row>
                    <Col className="prestations-text mt-3" xs={12} md={8} lg={6} xl={5}>
                        <p className="prestations-text-content text-center">
                            Le bistre est une croûte compacte dans le conduit et qui est à l’origine de la majeure partie des
                            incendies dans les foyers au bois. Afin d’éviter l'obstruation de votre conduit nous intervenons avec des
                            techniques modernes, assurance d’un résultat de qualité
                        </p>
                    </Col>
                </Row>
                <div
                    style={{
                        backgroundImage: `url(${presta_banner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="mobile-prestations-banner">

                </div>
                <Row className="mobile-prestations-card p-4 center">
                    <Row>
                        <Col>
                            <Col className="center" xs={12}>
                                <img className="prestations-icon" alt="devis rapide" src={medal_icon}/>
                            </Col>
                            <Col className="mt-3" xs={12}>
                                <h2 className="mobile-prestations-title text-center">DELIVRANCE CERTIFICAT</h2>
                            </Col>
                        </Col>
                    </Row>
                    <Col className="prestations-text mt-3" xs={12} md={8} lg={6} xl={5}>
                        <p className="prestations-text-content text-center">
                            L’entretien de votre conduit étant de votre responsabilité, vous êtes tenu de l’effectuer deux fois par an d’après la loi(ARTICLE 31-6 D.T.U. 21-1 ; 21-2).
                            Afin de vous garantir la conformité notamment auprès de votre assurance, nous vous remettons immédiatement votre certificat de ramonage
                        </p>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Prestations;