import React, {Component} from 'react';
import {Col, Nav, Row} from "react-bootstrap";
import logo from "../../../img/logos/logo-ab-ramonage.svg";
import {Link} from "react-router-dom";

import './DesktopFooter.css';

class DesktopFooter extends Component {
    render() {
        return (
            <>
                <Row className="footer-desktop-section row p-0">
                    <Col className="p-2" xs={1}>
                        <img className="footer-logo w-100" alt="AB-ramonage logo" src={logo}/>
                    </Col>
                    <Col className="footer-company-desk mt-3 p-0" xs={3}>
                        <h2 className="footer-company-title-desk">AB-RAMONAGE</h2>
                        <h3 className="footer-company-phone-desk">06 50 17 67 72</h3>
                        <p className="footer-company-text-desk">
                            contact@abramonage.com<br/>
                            94 rue Eugène Meissel 83450 Taradeau<br/>
                            SIREN 828 467 613
                        </p>
                    </Col>
                    <Col className="p-0" xs={4} xl={4}>
                        <h2 className="footer-services-desk mt-3 text-center">Ramonage tous conduits, foyer ouvert, insert, Poêle à bois / granule, Chaudière fioul / gaz, debistrage</h2>
                    </Col>
                    <Col className="p-0" xs={{span:2, offset: 2}} xl={{span:2, offset: 2}}>
                        <Nav className="w-75">
                            <Nav.Link as={Link} className='nav-footer-mobile-link-desk text-center mt-2' to="/" eventKey="/">- Accueil</Nav.Link>
                            <Nav.Link as={Link} className='nav-footer-mobile-link-desk text-center mt-3' to="/prestations" eventKey="/prestations">- Nos prestations</Nav.Link>
                            <Nav.Link as={Link} className='nav-footer-mobile-link-desk text-center mt-3' to="/contact" eventKey="/contact">- Nous contacter</Nav.Link>
                        </Nav>
                    </Col>
                    <Col className="center" xs={12}>
                        <p className="text-center mt-1 mb-0 company-sentence-desk">Made by e-kigaï-dev © 2020 - Tous droits réservés</p>
                    </Col>
                </Row>
            </>
        );
    }
}

export default DesktopFooter;