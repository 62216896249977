import React, {Component} from 'react';
import {Row, Col} from "react-bootstrap";

import './DesktopHeader.css';

import header_desk_background from '../../../img/backgrounds/header-desk-bg';


class DesktopHeader extends Component {
    render() {
        return (
            <>
                <header className="desktop-header">
                    <div
                        style={{
                            backgroundImage: `url(${header_desk_background})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        className="desktop-header-background">
                        <Row>
                            <Col xs={{span: 4, offset: 4}} xl={{span: 3, offset: 4}} className="pt-4">
                                <div className="card card-main-title-desk mx-auto">
                                    <div className="card-body">
                                        <h1 className="main-title-desk text-center">RAMONAGE TOUS CONDUITS  DEBISTRAGE, DEVIS GRATUIT, REMISE DE CERTIFICAT IMMÉDIATE DANS LE VAR</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} xl={3} className="header-phone-section">
                                <div className="card card-main-title-desk mx-auto">
                                    <div className="card-body">
                                        <h1 className="main-title text-center">Appelez nous au <u>06 50 17 67 72</u></h1>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </header>
            </>
        );
    }
}

export default DesktopHeader;