import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import './Homepage.css';

import left_quote from '../../img/icons/left_quote.svg';
import right_quote from '../../img/icons/right_quote.svg';
import hoover_icon from '../../img/icons/vacuum-cleaner.svg';
import wash_icon from '../../img/icons/bucket.svg';
import graduate_icon from '../../img/icons/graduation.svg';
import certif_icon from '../../img/icons/certificate (1).svg';
import chrono_icon from '../../img/icons/stopwatch.svg';
import eco_icon from '../../img/icons/green-energy.svg';

import banner_bg from '../../img/backgrounds/banner-bg-homepage.jpg';


class Homepage extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        const isDesktop = window.innerWidth >= 1024;
        return (
            <>
                <Row className="homepage-presentation-section container-fluid mt-5">
                    <Col xs={2} xl={3} className="left-quotes">
                        <img className="icon-quote icon-quote-left" src={left_quote} alt="guillemets gauche"/>
                    </Col>
                    <Col xs={8} xl={6} className="homepage-presentation w-75 center text-center mx-auto">
                        <p>Notre société AB Ramonage installée à Taradeau dans le Var intervient sur tout le département.
                            Que ce soit Frejus, Draguignan ou encore Toulon nous effectuons le déplacement.
                            Nous intervenons pour des opérations de ramonage ou débistrage, n’hésitez pas à consulter nos prestations pour en savoir plus</p>
                    </Col>
                    <Col xs={2} xl={3} className="right-quotes">
                       <img className="icon-quote" src={right_quote} alt="guillemets droit"/>
                    </Col>
                </Row>
                <div
                    style={{
                        backgroundImage: `url(${banner_bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="homepage-banner-separator mt-3">

                    {isDesktop ? <Link className="btn btn-lg btn-mobile-banner btn-presta-desk mt-4 center" to="/prestations"><span className="text-btn-homepage">NOS PRESTATIONS</span></Link> : <Link className="btn btn-mobile-banner mt-4 center" to="/prestations"><span className="text-btn-homepage">NOS PRESTATIONS</span></Link>}
                    {isDesktop ? <Link className="btn btn-lg btn-mobile-banner btn-contact-desk mt-4" to="/prestations">NOUS CONTACTER</Link> : '' }
                </div>
                <div className="mobile-engagements-section mb-5 container-fluid">
                    <h2 className="section-title text-center">NOS ENGAGEMENTS</h2>
                    <Row>
                        <Col xs={12} lg={4}>
                            <div className="mobile-card-engagements mt-4 p-4 card">
                                <Row>
                                    <Col xs={4}>
                                        <Row>
                                            <img className="mobile-engagements-icon-1 w-75 mt-2" alt="aspirateur" src={hoover_icon}/>
                                            <img className="mobile-engagements-icon-2 w-75" alt="nettoyage" src={wash_icon} />
                                        </Row>
                                    </Col>
                                    <Col xs={8}>
                                        <p className="mobile-engagements-text text-center ml-1">   Nous apportons une attention particulière à la satisfaction de nos clients.
                                            C’est pourquoi nous nous engageons à garder le lieu de l’intervention propre que ce soit intérieur ou extérieur
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="mobile-card-engagements mt-4 p-4 card">
                                <Row>
                                    <Col xs={8}>
                                        <p className="mobile-engagements-text text-center">      Nos techniciens sont tous diplômés d’état. Ce qui vous assure de rester en conformité avec votre assureur.
                                            De plus cela nous permet de vous délivrer immédiatement le certificat de ramonage
                                        </p>
                                    </Col>
                                    <Col xs={4}>
                                        <Row>
                                            <img className="mobile-engagements-icon-1 w-75 mt-2" alt="aspirateur" src={certif_icon}/>
                                            <img className="mobile-engagements-icon-2 w-75" alt="nettoyage" src={graduate_icon} />
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="mobile-card-engagements mt-4 p-4 card">
                                <Row>
                                    <Col xs={4}>
                                        <Row>
                                            <img className="mobile-engagements-icon-1 w-75 mt-2" alt="aspirateur" src={chrono_icon}/>
                                            <img className="mobile-engagements-icon-2 w-75" alt="nettoyage" src={eco_icon} />
                                        </Row>
                                    </Col>
                                    <Col xs={8}>
                                        <p className="mobile-engagements-text text-center ml-1">      Nous nous engageons à vous répondre dans les plus brefs délais.
                                            La rapidité et l’efficacité de notre intervention vous assurent de contribuer à l’écologie en rejetant moins de pollution
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Homepage;