import React, {Component} from 'react';
import {Link} from "react-router-dom";

import'./Notfound.css';

import logo from '../../img/logos/logo-ab-ramonage.svg';

class Notfound extends Component {
    render() {
        return (
            <>
                <div className="not-found-section">
                    <p className="not-found-title text-center mb-2">Vous êtes perdu ?</p>
                    <div className="center mt-3">
                        <Link className="btn btn-lg not-found-btn" to="/">Vers le site</Link>
                    </div>
                    <div className="center mt-5">
                        <img className="not-found-image" src={logo} alt="ab-ramonage"/>
                    </div>
                </div>
            </>
        );
    }
}

export default Notfound;