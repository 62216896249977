import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Prestations from "./components/Prestations/Prestations";
import Contact from "./components/Contact/Contact";
import Homepage from "./components/Homepage/Homepage";
import Notfound from "./components/Notfound/Notfound";
import MobileHeader from "./components/Header/MobileHeader/MobileHeader";
import DesktopHeader from "./components/Header/DesktopHeader/DesktopHeader";
import MobileFooter from "./components/Footer/MobileFooter/MobileFooter";
import DesktopFooter from "./components/Footer/DesktopFooter/DesktopFooter";
import DesktopNavbar from "./components/Navbar/DesktopNavbar/DesktopNavbar";
import MobileNavbar from "./components/Navbar/MobileNavbar/MobileNavbar";


class App extends Component {
    render() {
        const isMobile = window.innerWidth <= 768;

        return (
            <Router>
                <div className="App">
                    {!isMobile ? <DesktopNavbar/> : <MobileNavbar/>}
                    {isMobile ? <MobileHeader/> : <DesktopHeader/>}
                    <Switch>
                        <Route exact path="/prestations" component={Prestations}/>
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/" component={Homepage} />
                        <Route path="*" component={Notfound} />
                    </Switch>
                    {isMobile ? <MobileFooter/> : <DesktopFooter/>}
                </div>
            </Router>
        );
    }
}

export default App;

